import React, { memo } from "react";
import { FormGroup, Label } from "reactstrap";
import RequiredLabel from "./RequiredLabel";
import { PatternFormat } from "react-number-format";
import { validatePhoneNumber } from "../../../utils/validations";

const ContactInput = memo(({ label, name, value, onChange, isInvalid = "", required = false, setError }) => {
  if (!setError) {
    throw new Error("Error handler and error key are required");
  }

  const [errorHandler, errorKey] = setError;

  return (
    <FormGroup>
      {required ? <RequiredLabel htmlFor={name}>{label}</RequiredLabel> : <Label for={name}>{label}</Label>}
      <PatternFormat
        format="###########"
        autoComplete="off"
        name={name}
        id={name}
        value={value}
        allowEmptyFormatting
        mask="_"
        className={`form-control ${isInvalid ? "is-invalid" : ""}`}
        onValueChange={({ value }) => { // Use onValueChange to get the raw value without formatting
          const trimmedValue = value.replace(/\s+/g, ""); // Remove spaces
          if (trimmedValue === "___________") {
            onChange("");
          } else {
            onChange(trimmedValue);
            if (validatePhoneNumber(trimmedValue) && isInvalid) {
              errorHandler((pre) => ({ ...pre, [errorKey]: "" }));
            }
          }
        }}
      />
      <small className="text-danger">{isInvalid}</small>
    </FormGroup>
  );
});

export default ContactInput;
