import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useAddNewContext } from "../../../context/administration/addNewCase";
import axiosInstance from "../../../axiosInstance";
import useError from "../../../hooks/useError";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import instance from "../../../axiosInstance";
import ContactInput from "../../UI/forms/ContactInput";
import RequiredLabel from "../../UI/forms/RequiredLabel";
import ParamsLink from "../../UI/links/ParamsLink";
import { validatePhoneNumber } from "../../../utils/validations";
import MobileInput from "../../UI/forms/MobileInput";

export const titleOptions = [
  { value: "mr.", label: "Mr." },
  { value: "mrs.", label: "Mrs." },
  { value: "ms.", label: "Ms." },
  { value: "miss.", label: "Miss." },
  { value: "mstr.", label: "Mstr." },
];

export const serviceOptionsByCaseType = {
  rtadriver: ["personalInjury", "hire", "repair", "storage", "recovery", "inspection", "outlay"],
  rtapassenger: ["personalInjury"],
  rtamib: ["personalInjury", "hire", "repair"],
  "s&t": ["personalInjury"],
  publicliability: ["personalInjury"],
  occupiersliability: ["personalInjury"],
  workaccident: ["personalInjury"],
  housingdisrepair: ["personalInjury"],
  rtamotorcycle: ["personalInjury", "hire", "repair", "storage", "recovery", "inspection", "outlay"],
  rtaportal: ["personalInjury", "hire", "repair", "storage", "recovery", "inspection", "outlay"],
  rtanondriver: ["personalInjury", "hire", "repair", "storage", "recovery", "inspection", "outlay"],
  // 'other': ["personalInjury"]
};

const AddNewCaseForm = () => {
  const { handleError } = useError();
  const [caseTypes, setCaseTypes] = useState([]);

  useEffect(() => {
    const fetchCaseTypes = async () => {
      try {
        const response = await instance.get("lookups/caseTypes");
        const options = response.data.data.map((caseType) => ({
          value: caseType.toLowerCase().replace(/\s+/g, ""),
          label: caseType,
        }));
        setCaseTypes(options);
      } catch (err) {
        handleError(err);
      }
    };
    fetchCaseTypes();
  }, []);

  const {
    title,
    setTitle,
    first_name,
    setFirstName,
    middle_name,
    setMiddleName,
    last_name,
    setLastName,
    mobile_no,
    setMobilePhone,
    phone_no,
    setPhoneNo,
    case_type,
    setCaseType,
    service_type_personal_injury,
    setService_type_personal_injury,
    service_type_hire,
    setService_type_hire,
    service_type_repair,
    setService_type_repair,
    service_type_storage,
    setService_type_storage,
    service_type_recovery,
    setService_type_recovery,
    service_type_inspection,
    setService_type_inspection,
    service_type_outlay,
    setService_type_outlay,
  } = useAddNewContext();

  const availableServiceTypes = case_type ? serviceOptionsByCaseType[case_type.value] || [] : [];

  const [serviceTypeStates, setServiceTypeStates] = useState({
    personalInjury: [0, setService_type_personal_injury],
    hire: [0, setService_type_hire],
    repair: [0, setService_type_repair],
    storage: [0, setService_type_storage],
    recovery: [0, setService_type_recovery],
    inspection: [0, setService_type_inspection],
    outlay: [0, setService_type_outlay],
  });

  const [records, setRecords] = useState([]);
  // const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const serviceTypesOptions = [
    { id: "personalInjury", label: "Personal Injury" },
    { id: "hire", label: "Hire" },
    { id: "repair", label: "Repair" },
    { id: "storage", label: "Storage" },
    { id: "recovery", label: "Recovery" },
    { id: "inspection", label: "Inspection" },
    { id: "outlay", label: "Outlay" },
  ];

  const changeServiceTypes = (e) => {
    const serviceName = e.target.name;
    const isChecked = e.target.checked;

    setServiceTypeStates((prevServiceTypeStates) => {
      const [currentValue, setFunction] = prevServiceTypeStates[serviceName];

      setFunction(isChecked ? 1 : 0);

      return {
        ...prevServiceTypeStates,
        [serviceName]: [isChecked ? 1 : 0, setFunction],
      };
    });

    if (isChecked) {
      setValidationErrors((prevValidationErrors) => ({
        ...prevValidationErrors,
        serviceTypes: "",
      }));
    }
  };

  const resetServiceTypeCheckboxes = () => {
    const updatedServiceTypeStates = {};
    for (const [serviceName, [currentValue, setFunction]] of Object.entries(serviceTypeStates)) {
      setFunction(0);
      updatedServiceTypeStates[serviceName] = [0, setFunction];
    }
    setServiceTypeStates(updatedServiceTypeStates);
  };

  // --------------Form Custom Validation----------------
  const [validationErrors, setValidationErrors] = useState({
    mobileNumber: "",
    title: "",
    // homeTelephone: "",
    firstName: "",
    middleName: "",
    lastName: "",
    caseType: "",
    serviceTypes: "",
  });

  const validateErrors = () => {
    const errors = {
      mobileNumber: "",
      title: "",
      // homeTelephone: "",
      firstName: "",
      middleName: "",
      lastName: "",
      caseType: "",
      serviceTypes: "",
    };

    if (!title || title === "select") {
      errors.title = "Title is required";
    }

    if (!mobile_no.trim()) {
      errors.mobileNumber = "Mobile Number is required";
    }

    if (!mobile_no) {
      errors.mobileNumber = "Mobile Number is required";
    } else if (!validatePhoneNumber(mobile_no)) {
      errors.mobileNumber = "Invalid mobile number";
    }

    // if (!phone_no) {
    //   errors.homeTelephone = "Home Telephone is required";
    // } else if (!validatePhoneNumber(phone_no)) {
    //   errors.homeTelephone = "Invalid home telephone";
    // }

    if (!first_name.trim()) {
      errors.firstName = "First Name is required";
    }

    if (!last_name.trim()) {
      errors.lastName = "Last Name is required";
    }

    if (!case_type) {
      errors.caseType = "Case Type is required";
    }

    if (!service_type_personal_injury && !service_type_hire && !service_type_repair && !service_type_storage && !service_type_recovery && !service_type_inspection && !service_type_outlay) {
      errors.serviceTypes = "At least one service type is required";
    }

    setValidationErrors(errors);
    if (!Object.values(errors).every((error) => error === "")) {
      return false;
    } else {
      return true;
    }
  };
  // --------------Validation Ends----------------

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateErrors()) {
      return;
    }

    const formData = {
      title: title?.label,
      first_name,
      middle_name,
      last_name,
      mobile_no,
      phone_no,
      case_type: case_type?.label || "",
      service_type_personal_injury,
      service_type_hire,
      service_type_repair,
      service_type_storage,
      service_type_recovery,
      service_type_inspection,
      service_type_outlay,
    };

    try {
      setIsSubmitting(true);
      const response = await axiosInstance.post("/case/add", formData);
      toastr.success("Success", "Form Submitted Successfully");
      clearFormFields();
      setMobilePhone("");

      const caseId = response.data.data.case_id;
      history.push(`/administration/cases/${caseId}?from=add-new-case`);
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMobilePhoneChange = async (newMobileNumber) => {
    if (!newMobileNumber) {
      setMobilePhone("");
      clearFormFields();
      return;
    }

    setMobilePhone(newMobileNumber);

    try {
      const response = await axiosInstance.get(`/case/getDataFromMobile/${newMobileNumber}`);
      const userData = response.data;

      if (Array.isArray(userData.data) && userData.data.length > 0) {
        askUserToChooseRecord(userData.data);
      } else {
        clearFormFields();
        console.log("No user data found for the given mobile number");
      }
    } catch (error) {
      console.log("Error fetching user data from the server:", error);
      // handleError(error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const askUserToChooseRecord = async (fetchedRecords) => {
    if (fetchedRecords.length === 1) {
      updateFormFields(fetchedRecords[0]);
      return;
    }

    setIsModalOpen(true);
    setRecords(fetchedRecords);
  };

  const handleRecordChoice = (selectedRecord) => {
    setIsModalOpen(false);
    updateFormFields(selectedRecord);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const updateFormFields = (selectedRecord) => {
    Object.keys(selectedRecord).forEach((key) => {
      if (key in stateSetterFunctions) {
        stateSetterFunctions[key](selectedRecord[key]);
      }
    });

    if (selectedRecord.title) {
      const titleOption = titleOptions.find((option) => option.label === selectedRecord.title);
      if (titleOption) {
        setTitle(titleOption);
      }
    }
  };

  const clearFormFields = () => {
    setTitle("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setPhoneNo("");
  };

  const stateSetterFunctions = {
    title: setTitle,
    first_name: setFirstName,
    middle_name: setMiddleName,
    last_name: setLastName,
    phone_no: setPhoneNo,
  };

  useEffect(()=> {
    console.log(mobile_no)
  },[mobile_no])

  return (
    <>
      <Form onSubmit={handleSubmit} className="m-4">
        <Row>
          <Col md={6}>
            <MobileInput
              label="Mobile Phone"
              name="mobilePhone"
              required
              value={mobile_no}
              onChange={handleMobilePhoneChange}
              isInvalid={validationErrors.mobileNumber}
              setError={[setValidationErrors, "mobileNumber"]}
            />

            <FormGroup>
              <RequiredLabel htmlFor="title">Title</RequiredLabel>
              <Select
                className={`basic-single ${validationErrors.title ? "invalid-select" : ""}`}
                classNamePrefix="select"
                id="title"
                name="title"
                value={title}
                options={titleOptions}
                onChange={(value) => {
                  setTitle(value);
                  if (!value) {
                    setValidationErrors((prevValidationErrors) => ({ ...prevValidationErrors, title: "Title is required" }));
                  } else {
                    setValidationErrors((prevValidationErrors) => ({ ...prevValidationErrors, title: "" }));
                  }
                }}
              />
              <small className="text-danger">{validationErrors.title}</small>
            </FormGroup>

            <FormGroup>
              <RequiredLabel htmlFor="firstName">First Name</RequiredLabel>
              <Input
                type="text"
                id="firstName"
                autoComplete="off"
                name="firstName"
                className={validationErrors.firstName ? "is-invalid" : ""}
                value={first_name}
                onChange={(e) => {
                  const value = e.target.value;
                  setFirstName(value);
                  setValidationErrors((prevValidationErrors) => ({ ...prevValidationErrors, firstName: value.trim() ? "" : "First Name is required" }));
                }}
              />
              <small className="text-danger">{validationErrors.firstName}</small>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="middleName">Middle Name</Label>
              <Input
                type="text"
                autoComplete="off"
                id="middleName"
                name="middleName"
                value={middle_name}
                // className={validationErrors.middleName ? "is-invalid" : ""}
                onChange={(e) => {
                  const value = e.target.value;
                  setMiddleName(value);
                  // setValidationErrors((prevValidationErrors) => ({ ...prevValidationErrors, middleName: value.trim() ? "" : "Middle Name is required" }));
                }}
              />
              {/* <small className="text-danger">{validationErrors.middleName}</small> */}
            </FormGroup>

            <FormGroup>
              <RequiredLabel htmlFor="lastName">Last Name</RequiredLabel>
              <Input
                type="text"
                autoComplete="off"
                id="lastName"
                name="lastName"
                value={last_name}
                className={validationErrors.lastName ? "is-invalid" : ""}
                onChange={(e) => {
                  const value = e.target.value;
                  setLastName(value);
                  setValidationErrors((prevValidationErrors) => ({ ...prevValidationErrors, lastName: value.trim() ? "" : "Last Name is required" }));
                }}
              />
              <small className="text-danger">{validationErrors.lastName}</small>
            </FormGroup>
          </Col>

          <Col md={6}>
            <ContactInput
              label="Home Telephone"
              name="homeTelephone"
              // required
              value={phone_no}
              onChange={setPhoneNo}
              isInvalid={validationErrors.homeTelephone}
              setError={[setValidationErrors, "homeTelephone"]}
            />

            <FormGroup>
              <RequiredLabel htmlFor="caseType">Case Type</RequiredLabel>
              <Select
                id="caseType"
                name="caseType"
                className={`basic-single ${validationErrors.caseType ? "invalid-select" : ""}`}
                classNamePrefix="select"
                value={case_type}
                onChange={(value) => {
                  setCaseType(value);
                  resetServiceTypeCheckboxes();
                  setValidationErrors((prevValidationErrors) => ({ ...prevValidationErrors, caseType: value ? "" : "Case Type is required" }));
                }}
                isSearchable={true}
                options={caseTypes}
              />
              <small className="text-danger">{validationErrors.caseType}</small>
            </FormGroup>
            {case_type !== null && (
              <FormGroup>
                <RequiredLabel htmlFor="">Service Type</RequiredLabel>
                <div className="border p-2">
                  <Row className="ml-2">
                    {serviceTypesOptions.map(
                      (service) =>
                        availableServiceTypes.includes(service.id) && (
                          <Col md={12} key={service.id}>
                            <Label check>
                              <Input type="checkbox" id={service.id} name={service.id} checked={serviceTypeStates[service.id][0] === 1} onChange={changeServiceTypes} />
                              {service.label}
                            </Label>
                          </Col>
                        )
                    )}
                  </Row>
                </div>
                <small className="text-danger">{validationErrors.serviceTypes}</small>
              </FormGroup>
            )}
          </Col>
        </Row>

        <div className="d-flex justify-content-end mt-4">
          <ParamsLink to="/administration/cases" className="mr-3">
            <Button color="primary" type="button" className="">
              Exit
            </Button>
          </ParamsLink>

          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
            <span>Submit</span>
          </Button>
        </div>
      </Form>

      <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader>Choose a Record</ModalHeader>
        <ModalBody>
          <Label for="recordDropdown">Select a record:</Label>
          <Input
            type="select"
            name="recordDropdown"
            id="recordDropdown"
            onChange={(e) => {
              const selectedIndex = parseInt(e.target.value, 10) - 1;
              if (records[selectedIndex]) {
                handleRecordChoice(records[selectedIndex]);
              } else {
                console.error("Invalid selection");
              }
            }}
          >
            <option value={0}>Select a record</option>
            {records.map((record, index) => (
              <option key={index} value={index + 1}>
                {`${record.title} ${record.first_name} ${record.last_name}`}
              </option>
            ))}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddNewCaseForm;
